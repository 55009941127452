import { CHAINS } from "../Config/env";
import { STAKING_MASTERCHEF_ABI } from "../Config/stakeAbi";
import { toFixedNumber, UseERC20 } from "./useContract";
import { GetChainIndex, useWeb3 } from "./UseWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from './useToast'
import BigNumber from 'bignumber.js';


export const UseStakingAddress= async()=>{
    try{
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(STAKING_MASTERCHEF_ABI, getStakingAddress());
   
    return contract;
}catch (e) {
               
}  
}


export const getStakingAddress = ()=>{
    try{
    const index = GetChainIndex();
    const ContractAddress = CHAINS[index].LOCKEDSTAKING;
 
    return ContractAddress;
}catch (e) {
               
}
}


export const getPoolLength = async () => {
    try{
    
    const stakecontract = await UseStakingAddress();
    const length = await stakecontract.methods.poolLength().call();
   

   
    return length;
}catch (e) {
               
}
}


export const getPoolInfoLimit = async (start,end) => {
    try{
    const totalPool = [];
    const length = await getPoolLength();
    if(length > 0){
        for(var i=start;i<=end;i++){
            if(i<length){
                const poolarr = await getPool(i);
                totalPool.push(poolarr);
            }
        }
    }
    return totalPool;
 }catch (e) {
               
}
}

export const getPool = async(pid)=>{
try{
    const stakecontract = await UseStakingAddress();
    const Pool = await stakecontract.methods.poolInfo(pid).call();
  
    return Pool;
}catch (e) {
               
}
}


export const getUser = async(pid,account)=>{
    try{
       
        const stakecontract = await UseStakingAddress();
        const User = await stakecontract.methods.userInfo(pid,account).call();
        return User;
    }catch (e) {
                   
    }
    }


    export const UseTokenInfo = async (TOKEN) => {
        try{
        const token = await UseERC20(TOKEN);
        const name= await token.methods.name().call();
        const symbol= await token.methods.symbol().call();
        const decimals= await token.methods.decimals().call();
        const data = {
            name: name,
            symbol: symbol,
            decimals: decimals,
            address: TOKEN
        }
       
        return data;
    }catch (e) {
                   
    }
}


export const getUserReward = async (pid,account) => {
    try{
    const stakecontract = await UseStakingAddress();
    const user = await stakecontract.methods.pendingMeta(pid,account).call()
    return user;
}catch (e) {
               
}
    }


export const Claim = async(account , pid) =>{
        const stakingContract = await UseStakingAddress();
        const data =  stakingContract.methods.claim(pid).send({ from: account})
        await toast.promise(data, {
            loading: 'Claiming reward...',
            success: 'Claim Successfully',
            error: 'Try Again',
            }, 
                {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
            )
        await window.location.reload()
    } 


    export const checkIsApproved = async (account,token) => {
        try{
        const erc20contract = await UseERC20(token);
        const allow = await erc20contract.methods.allowance(account,getStakingAddress()).call();
       
        return (parseInt(allow)>0)?true:false;
    }catch (e) {
                   
    }
    }
    
    export const approveContract = async(account,token)=>{
       console.log(account,token);
        const erc20Contract = await UseERC20(token);
        const data = erc20Contract.methods.approve(getStakingAddress(),"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
        await toast.promise(data, {
         loading: 'Approving...',
         success: 'Approved Successfully',
         error: 'Try Again',
         }, 
            {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            }
         )
    }    


    export const getUserBalance = async (token,account) => {
        try{
        const tokenContract = await UseERC20(token);
        const tokenInfo = await UseTokenInfo(token);
        const balance = await tokenContract.methods.balanceOf(account).call();
        const balanceTokens = ((new BigNumber(balance)).div(new BigNumber(10).pow(tokenInfo.decimals))).toNumber();
        return balanceTokens.toString();
    }catch (e) {
                   
    }
    }



    export const OnStake = async(account , pid, amount, decimal, isMax) =>{
        
        const stakingContract = await UseStakingAddress();
        const convertedAmount = getWholeNumber(amount,decimal);
       const data =  stakingContract.methods.deposit(pid.toString(),convertedAmount.toString()).send({ from: account });
       await toast.promise(data, {
        loading: 'Depositing ...',
        success: 'Deposit Successfully',
        error: 'Try Again',
        }, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        }
        )
        await window.location.reload()
    }


    export const UnStake = async(account , pid, amount, decimal,isMax) =>{

        const stakingContract = await UseStakingAddress();
        const amt = isMax ? amount : toFixedNumber(amount * 10 ** decimal);
     
        // const convertedAmount = getWholeNumber(amount,decimal);
        const data = stakingContract.methods.withdraw(pid,amt.toString()).send({ from: account });
            await toast.promise(data, {
            loading: 'Withdraw ...',
            success: 'Withdraw Successfully',
            error: 'Try Again',
            }, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            }
            )
            await window.location.reload()
    }
    export const getWholeNumber = (value,decimal) => {
        try{
        const balance =  new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = (balance).toNumber();
        finalval = toFixedNumber(finalval);
        return finalval.toString();
    }catch (e) {
                   
    }
    }    