import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';
import { Tabs, Tab } from 'react-bootstrap';
import Masp from './Modal/MaspModal';
import LaunchpadCont from './LaunchpadCont';
import logoicon from '../Assets/images/slide/openGPT.png'
import { getLaunchpadData } from '../Hooks/UseBackend';
import Typed from 'react-typed';
import Swal from 'sweetalert2';
// import circle from "../Assets/images/circle.png";

import loopsimg from "../Assets/images/slide/loop-gif.gif";
import loopsleftimg from "../Assets/images/slide/loopleft.gif";
import targets from "../Assets/images/slide/target.gif";

const Launchpad = () => {
    const [masp, setMasp] = useState(false)
    const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])


    useEffect(() => {

        getData()
    }, [])

    const getData = async () => {

        var data = await getLaunchpadData();
        console.log("data>>>>",data);
        settotalLaunchPadlength(data)
        //  data.map(async(item,index) =>{
        //  var result = await UsegetSaleInfo(item?.presaleAddress);

        //  })
    }


    const swals = () =>{
        Swal.fire({
            title: "Warning",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          });
    }


    return (
        <div className='launchpad'>
            <Header />
            <div className='allsection'>
                <div className='container container-theme my-4'>
                    <div className='br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center position-relative'>
                        <div>
                            <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientleft'></span>
                            <span className='greengradientright'></span>
                            <img src={loopsimg} className='loopsimg' alt='targets' />
                            <img src={loopsleftimg} className='loopsleftimg' alt='targets' />
                            <img src={targets} className='targetcircle' alt='targets' />
                            <h1 className='innerheading'>Launchpad</h1>
                           
                            {/* <p>Buy New Tokens Before They Are Launched for Trading</p> */}
                            <div className='mt-3 titles'>
                                <Typed
                                    strings={['Buy New Tokens Before They Are Launched for Trading']}
                                    typeSpeed={100}
                                    cursorChar={"_"}
                                    loop
                                />
                            </div>
                        </div>
                    </div>


                    {/* <button className='ovalbtn' onClick={()=>swals()}>Warning</button> */}


                    <div className='showloader minwidth-534 text-center' id="loader_div">
                        <div className='loader ' >
                            <img src={logoicon} className="spin" />

                        </div>

                    </div>
                    <div className='menu_list position-relative'>

                        <div className='notice_link'> <a target="_blank"
                            // href='https://www.labelm.io/newsdetails/63d1edb1ecf0432c7ebd9869' 
                            href='/'
                            className='noticelink'>-Notice-</a></div>

                        {/* <div className='notice_link'> <a href='/news/#latest_article' className='noticelink'>-Notice-</a></div> */}
                        <div>
                            <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3 mt-5"  >
                                <Tab eventKey="all" title="All">
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => <LaunchpadCont data={item} pid={index} /> )}
                                </Tab>
                                <Tab eventKey="launch1" title="90 Days">
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 0 || item.pid == 3 || item.pid == 7 || item.pid == 10 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch2" title="180 Days" >
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 1 || item.pid == 4 || item.pid == 8  ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch3" title="365 Days" >
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 2 || item.pid == 5 ||  item.pid == 9 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>
                                <Tab eventKey="launch4" title="5 Days" >
                                    {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 6  ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                </Tab>

                            </Tabs>

                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Launchpad