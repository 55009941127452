import logo from './logo.svg';
import './App.css';
import './Style.css';
import './home.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Home from './Component/Home';
import Launchpad from './Component/Launchpad';
import LockedStake from './Component/LockedStaking';
import News from './Component/News';
import Newsdetails from './Component/Newsdetails';
import Articles from './Component/Articles';
import toast, { Toaster } from 'react-hot-toast';
import Map from './Component/Map';

import Home1 from './Component/Home1';
import Demo from "./Component/demo";

function App() {
  return (
    <div className="App">
       <BrowserRouter >
       <div><Toaster /></div>
       <Routes>
          {/* <Route path="/" element={<Land />} /> */}
          {/* <Route path="/" element={<Home1 />} /> */}
          <Route path="/" element={<Demo />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/staking" element={<LockedStake />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsdetails" element={<Newsdetails />} />
          <Route path="/newsdetails/:id" element={<Newsdetails />} />
          <Route path="/latestarticles/:id" element={<Articles />} />
          <Route path="/map" element={<Map />} />
          
          <Route path="/demo" element={<Demo />} />

       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
